import $ from 'jquery';
import flatpickr from 'flatpickr';


const prettyDatePickers =  {
    "init": function() {
        $('[type="date"]').each(function () {
            let options = {
                altInput: true,
                minDate: "2022-02",
                altFormat: 'j M Y',
                monthSelectorType: 'static',
                yearSelectorType: 'static',
                nextArrow: '<svg class="icon icon-next"><use xlink:href="img/icons/icons.svg#icon-fleche"></use></svg>',
                prevArrow: '<svg class="icon icon-prev"><use xlink:href="img/icons/icons.svg#icon-fleche"></use></svg>',
            };

            flatpickr($(this), options);
        })
    }
}

export default prettyDatePickers;

window.PPPdatePickers = prettyDatePickers;