import  $ from "jquery";
const mailForm = {
    "validateEmail": function(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    },

    "appendErrorMsg": function(input) {
        if (input.next('.js-error-mail').length === 0) {
            let emailError = $('#emailError').html();
            input.addClass('error');
            $(emailError).insertAfter(input);
        }
    },

    "mailError": function(parent) {
        const self = this;
        parent.find('.form-control[type="email"]').each( function() {
            $(this).on('keyup', function(e) {
                e.stopImmediatePropagation();
                e.stopPropagation();
                let input= $(e.target);
                let value = input.val();
                let errorMsg = input.next('.form-error');
                if (self.validateEmail(value) === true && errorMsg.length) {
                    input.removeClass('error');
                    errorMsg.remove();
                }
            }) 
        })
    },

    "checkField": function(input) {
        const self = this;
        let value = input.val();
        let isEmail;
        if (input.attr('type') === "email") {
            isEmail = self.validateEmail(value);
            if ( isEmail != true) {
              self.appendErrorMsg(input)
            } 
        }
    },

    "init": function() {
        const self = this;
        $('.js-check-mail').each(function() {
            let parent = $(this);
            let validateBtn = parent.next('.add-link');
            let formFields = parent;
            self.mailError(parent);
            validateBtn.on('click', function(e) {
                e.preventDefault();
                formFields.each(function() {
                    $(this).find('.form-control').each(function() {
                        let input = $(this);
                        self.checkField(input);
                    })
                })
            })
            
        })
    },
};

export default mailForm;

window.PPPmailForm = mailForm;