import  $ from "jquery";

const fileInput = {
    "init": function() {
        const self = this;
        if($('.js-file-preview').length) {
            $('.js-file-preview').each(function() {
                let inputGroup = $(this);
                let template = $("#ProgressScript").html();
                $(this).find('.js-file-preview-input').on('change', function(e) {
                    let fileName = e.target.files[0].name;
                    let progress = $(template);
                    progress.find('.js-file-name').text(fileName);
                    console.log( progress.html())
                    inputGroup.append(progress);
                })
            })
        }
    },

    "appendProgress": function(input) {
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = function (e) {
               console.log(e.target)
            }
            reader.readAsDataURL(input.files[0]);
        }
    }
};

export default fileInput;

window.PPPfileInput = fileInput;