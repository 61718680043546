import general from './main';
import  $ from "jquery";

const stickyElt = {
    "closeStickyBox": function(scrollDistance) {
        // Set the window's scroll position back to what it was before the modal was opened
      
        $("body").css("top", "");
        $(window).scrollTop(scrollDistance);  
        $('body').removeClass('modal-open', true);
        $('.js-sticky-box').removeClass('--open', true);
        $('.js-sticky-box-toggler').removeClass('active', true)
        $('.dark-backdrop').removeClass('d-block', true);
        $('.js-sticky-box').removeClass('show', true);
       
       
    },
    
    "mobileStickyBox": function() {
        let self = this;

        var scrollDistance = 0;

        if ($('.js-sticky-box').length) {
            if ($(window).width() < general.mqBreakpoints.md) {
            let toggler = $('.js-sticky-box-toggler');
            toggler.on('click', function() {
                // Get the scroll distance at the time the modal was opened
                scrollDistance = $(window).scrollTop();
                // Pull the top of the body up by that amount
                $("body").css("top", scrollDistance * -1);
                $('body').toggleClass('modal-open');
                $('.dark-backdrop').toggleClass('d-block');
                $('.js-sticky-box').toggleClass('--open');
                $(this).toggleClass('active');
   
                if ($('.js-sticky-box').hasClass('show')) {
                    $('.js-sticky-box').removeClass('show', true);
                } else {
                    setTimeout(function(){
                        $('.js-sticky-box').toggleClass('show');
                    }, 300);
                }
            })

            $('body').on('click', function(e) {
                if ($('.js-sticky-box').hasClass('show') && general.detectClickOutsideActive(e, $('.js-sticky-box'))) {
                    self.closeStickyBox(scrollDistance);
                }
            })

            } else {
                self.closeStickyBox(scrollDistance);
            }
        }
    },
  
}

export default stickyElt;