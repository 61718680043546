import '../scss/main.scss';
import  $ from "jquery";
import 'bootstrap';
import 'bootstrap-select';
import "bootstrap-select/sass/bootstrap-select.scss";
import AOS from 'aos';

import ideaForm from './ideaForm';
import PPPSwipers from './swipers';
import Toolbar from './toolbar';
import stickyElt from './stickyBox';
import addModal from './addModal';
import prettyDatePickers from './datepicker';
import fileInput from './fileInput';
import mailForm from './mailForm';

const general = {
  "mqBreakpoints": {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1156
  },

  "setPaddingTop": function () {
    let self = this;
    if ($('.js-set-padding-top').length && $('header').length) {
      let root = document.documentElement,
        headerOffset = 0,
        headerHeight;
        headerHeight = $('header').innerHeight();

      if ($(window).width() >= this.mqBreakpoints.lg) {
        headerOffset = 10;
      }
      
      if (headerHeight + headerOffset < 50) {
        root.style.setProperty('--header-height', "50px");
        if ($(window).width() >= self.mqBreakpoints.lg) {
          root.style.setProperty('--header-height', "100px");
        }
      } else {
        root.style.setProperty('--header-height', headerHeight + headerOffset + "px");
      }
    }
  },


  "disabledScrollModal": function() {
    var $window = $(window),
        $body = $("body"),
        $modal = $(".modal"),
        scrollDistance = 0;

    $modal.on("show.bs.modal", function() {
        // Get the scroll distance at the time the modal was opened
        scrollDistance = $window.scrollTop();

        // Pull the top of the body up by that amount
        $body.css("top", scrollDistance * -1);
    });


    $modal.on("hidden.bs.modal", function() {
      // Remove the negative top value on the body
      $body.css("top", "");

      // Set the window's scroll position back to what it was before the modal was opened
      $window.scrollTop(scrollDistance);  
    });
  },


  "preventScrollOpenedMenu": function () {
    if ($(window).width() < this.mqBreakpoints.md) {
      $('header .navbar-collapse').on('show.bs.collapse', function () {
        $('html').addClass('menu-open');
      })
      $('header .navbar-collapse').on('hide.bs.collapse', function () {
        $('html').removeClass('menu-open');
      })
    } else {
      $('html').removeClass('menu-open');
    }
  },

  "closeMenu": function() {
    $('#mainmenu').collapse('hide');
    $('.main-nav').removeClass('menu-open');
    $('html').removeClass('menu-open');
  },

  "addClassOnOpenedMenu": function () {
    if ($(window).width() <= this.mqBreakpoints.md) {
      $('.main-nav').on('show.bs.collapse', function () {
        $(this).addClass('menu-open');
        $('html').addClass('menu-open');
      }).on('hidden.bs.collapse', function () {
        $(this).removeClass('menu-open');
        $('html').removeClass('menu-open');
      })
    } else {
        $(this).removeClass('menu-open');
        $('html').removeClass('menu-open');
    }
  },

  "addActiveClassOnScroll": function () {
    if ($('.js-active-class-onscroll').length) {
      var addClass = function () {
        var scroll = $(window).scrollTop();
        if (scroll >= 20) {
          $(".js-active-class-onscroll").addClass("active");
        } else {
          $(".js-active-class-onscroll").removeClass("active");
        }
      }
      addClass();
      $(window).scroll(function () {
        addClass();
      });
    }
  },

  "setSameHeight": function () {
    if ($('.js-same-height-container').length) {
      var setHeight = function () {
        var maxHeight = 0;
        $('.js-same-height-container').each(function () {
          var elements = $(this).find('.js-same-height');
          var elementsMax = $(this).find('.js-same-height-max');
          elements.each(function () {
            if ($(this).outerHeight() > maxHeight) {
              maxHeight = $(this).outerHeight();
            }
          });
          elements.css('height', maxHeight);
          if ($('.js-same-height-max').length) {
            elementsMax.css('max-height', maxHeight, true);
          }
        });
      };

      if ($('.js-same-height-container').hasClass('-tablet')) {
        if ($(window).width() >= this.mqBreakpoints.md) {
          setHeight();
        }
      } else {
        setHeight();
      }

    }
  },

  "getViewPortHeight": function() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  },

  "closeDropdown": function() {
    if ($('.js-close-dropdown-menu').length) {
      $('.js-close-dropdown-menu').on('click', function(e) {
        $(this).dropdown('hide');
      })
    }
  },

  "detectClickOutsideActive": function(event, el) {
    var $target = $(event.target);
    if(!$target.closest(el).length) {
      return true;
    }    
  },


  "initSelectLists": function () {
    if ($(window).width() >= this.mqBreakpoints.md) {
      if ($('.js-select').length) {
        $('.js-select').each(function() {
            $(this).selectpicker();
        })
      }
    }
   },

   "removeElts": function() {
     let self = this;
     if ($('.js-removeEltsMobileContainer').length) {
        $('.js-removeEltsMobileContainer').each(function() {
          let eltsToKeep = $(this).attr('data-elts');
          let elts = $(this).find('.js-removeEltsMobile');
          elts.removeClass('js-removeEltsMobile');
          if ($(window).width() < self.mqBreakpoints.md) {
            elts.slice(eltsToKeep).remove();
          }
        })
     }
   },

   "changeContentwRadio": function() {
      if ($('.js-radio-show-content-container').length) {
          let container = $('.js-radio-show-content-container'),
              content = $('.js-radio-show-content'),
              activeContent;
          const showContent = function() {
              content.removeClass('d-block');
              content.addClass('d-none');
              let checkedVal;
              $('.js-radio-show-content-radio:checked').each(function() {
                  checkedVal = $(this).val();
                  activeContent = container.find('.js-radio-show-content[data-value=' + checkedVal + ']');
                  activeContent.addClass('d-block');
                  activeContent.removeClass('d-none');
              })
          };
          showContent();
          container.find('.js-radio-show-content-radio').on('change', function() {
              showContent();
          });
      }
    },


   "selectContent": function() {
    let self = this;
    if ($('.js-change-content-select-container').length) {
      
      const changeContent = (el, target) => {
        let str = $(target).find('option:selected').attr('data-'+el+'');
        let elContainer = $('.js-change-content-select-container').find('.js-data-'+el+'');
        if (el === 'mail' || el === 'phone') {
          elContainer.find('span').text(str);
          if (el === 'mail') {
            elContainer.attr('href', 'mailto:' + str)
          }
          if (el === 'phone') {
            elContainer.attr('href', 'tel:' + str)
          }
        } else {
          elContainer.text(str);
        }
      }

      changeContent('name', '.js-select');
      changeContent('fonction', '.js-select');
      changeContent('title', '.js-select');
      changeContent('mail', '.js-select');
      changeContent('phone', '.js-select');
      
      if ($(window).width() >= this.mqBreakpoints.md) {
        $('#jointCallSelect').on('changed.bs.select', function (e) {
          changeContent('name', e.currentTarget);
          changeContent('fonction', e.currentTarget);
          changeContent('title', e.currentTarget);
          changeContent('mail', e.currentTarget);
          changeContent('phone', e.currentTarget);
        });
      } else {
        $('#jointCallSelect').on('change', function (e) {
          changeContent('name', e.currentTarget);
          changeContent('fonction', e.currentTarget);
          changeContent('title', e.currentTarget);
          changeContent('mail', e.currentTarget);
          changeContent('phone', e.currentTarget);
        })
      }
    }
   },

   "scrollAnimation": function() {
     let self = this;
      AOS.init({
          disable: false,
          once: true, // whether animation should happen only once - while scrolling down
          offset: 0, // offset (in px) from the original trigger point
          duration: 1000, // values from 0 to 3000, with step 50ms
      });

      let aosElts = $('[data-aos-offset="250"]');

      let aosMobileLEts = $('[data-aos-delay-mobile]');
      let aosTabletLEts = $('[data-aos-delay-tablet]');
      
      if ($(window).width() < self.mqBreakpoints.md) {
        aosElts.each(function() {
          $(this).attr("data-aos-offset", "130");
        })
      } else if ($(window).width() === self.mqBreakpoints.md) {
        $(this).attr("data-aos-offset", "180");
      } else {
        aosElts.each(function() {
          $(this).attr("data-aos-offset", "250");
        })
      }

      if ($(window).width() < self.mqBreakpoints.sm) {
        aosMobileLEts.each(function() {
          let value = $(this).attr("data-aos-delay-mobile");
          $(this).attr("data-aos-delay", value);
        })
      }

      if ($(window).width() >= self.mqBreakpoints.md && $(window).width() < self.mqBreakpoints.lg) {
        aosTabletLEts.each(function() {
          let value = $(this).attr("data-aos-delay-tablet");
          $(this).attr("data-aos-delay", value);
        })
      }

      AOS.refreshHard();
   },

   "changeBtnText": function() {
    if ($('.js-change-text-container').length) {
        let radios = $('.js-change-text-container').find('input[type="radio"]');
        let textContainer = $('.js-change-text');
        let checkedRadio = $('.js-change-text-container').find('input[type="radio"]:checked');

        let checkedRadioId = checkedRadio.attr('id');
        let checkedRadiolabelEl = $('.js-change-text-container').find('label[for=' + checkedRadioId + '] ').html();
        textContainer.html(' ');
        textContainer.html(checkedRadiolabelEl);

        $('.js-change-text-container').on('change', '[type="radio"]', function () {
          let checked = this.checked;
          if (checked) {
            let checkedId = $(this).attr('id');
            let labelEl = $('.js-change-text-container').find('label[for=' + checkedId + '] ').html();
            textContainer.html(' ');
            textContainer.html(labelEl);
          }
      });
    }
  },

  "backTop": function() {
    if ($('.js-back-to-top').length) {
      let scrollDistance = $(window).scrollTop();
      $(window).on('scroll',function () {
        scrollDistance = $(window).scrollTop();
        if (scrollDistance > $(window).height() * 0.5) {
          $('.js-back-to-top').removeClass('hidden');
        } else {
          $('.js-back-to-top').addClass('hidden');
        }
      });

      $('.js-back-to-top').on('click', function(e) {
        e.preventDefault();
        window.scrollTo({top: 0, behavior: 'smooth'});
      })
    }
  },

  "openFirstCollapse": function() {
    if ($('.js-open-first-collapse').length) {
      $('.js-open-first-collapse').each(function() {
        $(this).find('.collapse').collapse('show');
      })
    }
  },


  "isiOS": function() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }
}

export default general;

window.PPPfunctions = general;

(function($){
  $(document).ready(function () {

    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    })

    if (general.isiOS()) {
      $('body').addClass('ios-browser');
    }

    $('body').on('change', '.js-check-mail-form', function() {
      mailForm.init();
    })

    fileInput.init();

    prettyDatePickers.init();

    PPPSwipers.filtersSwiper();
    PPPSwipers.listingSwiper();
    PPPSwipers.textSwiper();
    PPPSwipers.stepperSwiper();

    general.preventScrollOpenedMenu();
    general.addClassOnOpenedMenu();
    general.addActiveClassOnScroll();
    general.getViewPortHeight();
    general.closeDropdown();
    general.setPaddingTop();
    general.initSelectLists();
    general.setSameHeight();
    general.removeElts();
    general.scrollAnimation();
    general.disabledScrollModal();
    general.selectContent();
    general.changeContentwRadio();
    general.changeBtnText();
    general.backTop();
    general.openFirstCollapse();

    stickyElt.mobileStickyBox();

    Toolbar.toggleToolbar();

    ideaForm.countInputChars();
    ideaForm.initForm();
    ideaForm.filePreview(document.querySelector('.js-file-input'));

    addModal.init();

    if ($('.js-clearInput').length) {
      document.querySelector('.js-clearInput').addEventListener('click', function(e) {
        ideaForm.clearFileInput(document.querySelector('.js-clearInput'), document.querySelector('.js-file-input'),document.querySelector('.js-img-preview'));
      })
    }
    if ($('.js-reset').length) {
      document.querySelector('.js-reset').addEventListener('click', function(e) {
        ideaForm.clearFileInput(document.querySelector('.js-clearInput'), document.querySelector('.js-file-input'),document.querySelector('.js-img-preview'));
      })
    }
  

    var windowWidth = $(window).width();
    $(window).on('resize', function(){
      general.getViewPortHeight();

      if ($(window).width() != windowWidth) {
        $('.dropdown-toggle').dropdown('hide');
        AOS.refreshHard();

        prettyDatePickers.init();

        PPPSwipers.listingSwiper();
        PPPSwipers.filtersSwiper();
        PPPSwipers.textSwiper();
        PPPSwipers.stepperSwiper();

        general.setPaddingTop();
        general.closeMenu();
        general.setSameHeight();

        Toolbar.toggleToolbar();
        Toolbar.closeToolbar();

      }  
    });

    $(document).ajaxSuccess(function() {
      general.initSelectLists();
    });

    
  });


})($);
