import  $ from "jquery";
import mailForm from "./mailForm";

const ideaForm = {

    "countInputChars": function() {
        if ($('.js-max-char-input').length) {
          var inputGroup = $('.js-max-char-input');
      
          const setMaxVal = function(elContainer) {
            var maxVal = elContainer.attr('data-char');
            var maxValText = elContainer.find('.max');
            maxValText.text(maxVal)
          }
          
          const updateCount = function(inputEl, showVal) {
              var value = inputEl.val().length;
              showVal.text(value);
          }
      
          inputGroup.each(function() {
            var inputEl = $(this).find('.form-control');
            var showValEl = $(this).find('.js-max-char-input-count .count');
            setMaxVal($(this))
            inputEl.on('keyup', function() {
              updateCount($(this),showValEl);
            })
            inputEl.on('keydown', function() {
              updateCount($(this),showValEl);
            })
          });
        }
    },

    "initForm": function() {
        let self = this;

        mailForm.init();

        if ($('.js-add-form').length) {

            $('.js-keyword').each(function() {
                $(this).on('keyup', function(e) {
                    self.handleInputChange($(this), '.js-keyword'); 
                })
            })
    
            $('.js-expertise').each(function() {
                $(this).on('keyup', function(e) {
                    self.handleInputChange($(this), '.js-expertise'); 
                })
            })
    
            $('body').on('click', '.js-add-form-expertise-btn', function(e) {
                e.preventDefault();
                self.initAddGroup($(this),'#expertiseScript','.js-expertise','.form-expertise', '.js-add-form');
            });
    
            $('body').on('click', '.js-add-form-keyword-btn', function(e) {
                e.preventDefault();
                self.initAddGroup($(this),'#keywordScript','.js-keyword','.form-keyword', '.js-add-form');
            });

            $('body').on('click', '.js-add-form-partner-btn', function(e) {
                e.preventDefault();
                self.initAddGroup($(this),'#partnerScript','.js-partner','.form-partner', '.js-add-form');
            });

            $('body').on('click', '.js-add-form-colleague-btn', function(e) {
                e.preventDefault();
                self.initAddGroup($(this),'#colleagueScript','.js-colleague','.form-colleague', '.js-add-form');
            })

            $('body').on('click', '.js-add-form-company-btn', function(e) {
                e.preventDefault();
                self.initAddGroup($(this),'#companyScript','.js-company','.form-company', '.js-add-form-company');
            })

            $('body').on('click', '.js-add-form-organization-btn', function(e) {
                e.preventDefault();
                self.initAddGroup($(this),'#organizationScript','.js-organization','.form-organization', '.js-add-form-organization');
            })
        }

    },


    "initAddGroup": function(clickedEl, scriptEl, jsFormName, formName, formContainer) {
        let self = this;
        const $thatEl = clickedEl;
        const template = $(scriptEl).html();
        let errorMessage = $thatEl.closest(formContainer).find('.js-error');

        if (self.checkIfBeforeGroupIsPopulated($thatEl, jsFormName) && $(formContainer).find('.js-error-mail').length == 0) {
            // get HighestId
            errorMessage.remove();
            const latestGroup = $thatEl.prev().find(jsFormName);
            const id = parseInt(latestGroup.attr('id').split('-').pop()) + 1;
            let $groupField = $(template.replace(/\{id\}/g, id));
            $groupField
                .insertBefore($thatEl)
                .on('click', '.js-removeGroupButton', function() {
                    const $that = $(this);
                    const container = $that.parent(formName);

                    container.find(jsFormName).val('').trigger('change');
                    container.remove();
                });

            const input = $groupField.find(jsFormName);

            mailForm.mailError($('.js-add-modal'));

            $(jsFormName).each(function() {
                $(this).on('keyup', function(e) {
                    self.handleInputChange($(this), jsFormName, formContainer); 
                })
            })
        }  else if (!errorMessage.length) {
            self.appendErrorMessage($thatEl);
        }   
    },

    "appendErrorMessage": function (input) {
        let template = $($('#formError').html());
        $(template).insertAfter(input);
    },


    "handleInputChange": function(input, jsFormName, formContainer) {
        let self = this;
        let errorMessage = $(formContainer).find('.js-error');
        if (self.checkIfBeforeGroupIsPopulated(input, jsFormName) && errorMessage.length) {
            errorMessage.remove();
        }
    },

    "checkIfBeforeGroupIsPopulated": function (input, jsFormName) {
        let prevGroup = input.prev();
        let inputEl = prevGroup.find(jsFormName);
        if (inputEl.val() === "") {
            return false;
        }
        return true;
    },

    "filePreview": function(input) {
        if (input) {
            input.addEventListener('change', function(e) {
                if (e.target.files && e.target.files[0]) {
                    var reader = new FileReader();
                    reader.onload = function (e) {
                       $('.add-img').addClass('d-none', true);
                        $('.js-clearInput').removeClass('d-none', true);
                        $('.js-img-preview')
                            .addClass('show')
                            .attr('src', e.target.result);
                    };
                    reader.readAsDataURL(e.target.files[0]);
                }
            })
        }
    },

    "clearFileInput": function(btn, input, img) {
        input.value = "";
        img.src = "";
        btn.classList.add('d-none');
        $('.add-img').removeClass('d-none', true);
    },

   
}

export default ideaForm;

window.PPPideaForm = ideaForm;