
import general from './main';
import  $ from "jquery";

import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';

//configure Swiper to use modules
Swiper.use([Navigation, Pagination]);

const PPPSwipers = {

    "initSwiperBehavior": function(swiperEl) {
        swiperEl.init();
        swiperEl.setTranslate(0);
    },

    "setSwiperActiveEl": function(swiperElt, currentSwiper, activeEl) {
        let sticker = swiperElt.find(activeEl);
        let activeSlideIndex = currentSwiper.activeIndex;
        for (let i = 0; i < sticker.length; i++) {
          sticker[i].classList.remove('active');
        }
        sticker[activeSlideIndex].classList.add('active');
     },
  
     "killSwiper": function(swiperEl) {
       swiperEl.destroy(true, true);
     },
  
     "textSwiper": function() {
       let self = this;
      if ($('.swiper-text-container').length) {
       $('.swiper-text-container').each(function () {
        let swiperElt = $(this)
         $(this).find('.swiper-wrapper').removeClass('no-translate');
  
         var swiperText = new Swiper($(this)[0], {
             slidesPerView: 1,
             spaceBetween: 30,
             observer: true,
             observeParents: true,
             preventClicks: true,
             pagination: {
               el: '.swiper-text__hiddenpagination',
               clickable: true,
             },
             navigation: {
                 nextEl: '.swiper-text__next',
                 prevEl: '.swiper-text__prev',
             },
             breakpoints: {
              768: {
                slidesPerView: 1.25,
              },
              1024: {
                slidesPerView: 1.325,
              },
            },
             on: {
                 init: function () {
                     this.setTranslate(0);
                     self.setSwiperActiveEl(swiperElt, this, '.numbered-list > li');
                 },
                 resize: function () {
                    self.initSwiperBehavior(this);
                 },
                 slideChangeTransitionStart: function() {
                    self.setSwiperActiveEl(swiperElt, this, '.numbered-list > li');
                }
  
             }
         });
          self.initSwiperBehavior(swiperText);
       });
     }
    },
  
     "listingSwiper": function() {
       let self = this;
        if ($('.listing-swiper-container').length) {
        $('.listing-swiper-container').each(function () {
          
          $(this).find('.swiper-wrapper').removeClass('no-translate');
          
          let bkptSettings;
          if ($(this).hasClass('--offset-left')) {
              bkptSettings = {
                768: {
                  slidesPerView: 2.25,
                },
                1200: {
                  slidesPerView: 2.85,
                },
            } 
            } else {
                bkptSettings = {
                  768: {
                    slidesPerView: 2.25,
                  },
                  1024: {
                    slidesPerView: 2.75,
                  },
                  1200: {
                    slidesPerView: 3,
                  },
            }
          }
          
          var swiperListing = new Swiper($(this)[0], {
              slidesPerView: 1.125,
              spaceBetween: 20,
              observer: true,
              observeParents: true,
              preventClicks: true,
              navigation: {
                  nextEl: '.listing-swiper__next',
                  prevEl: '.listing-swiper__prev',
              },
              breakpoints: bkptSettings,
              on: {
                  init: function () {
                      this.setTranslate(0);
                  },
                  resize: function () {
                      if ($(this).hasClass('--tablet-swiper')) {
                        if ($(window).width() >= general.mqBreakpoints.md) {
                          self.initSwiperBehavior(this);
                        } else if(this) {
                          self.killSwiper(this)
                        }
                      } else {
                          self.initSwiperBehavior(this);
                      }
                  },
              }
          });
  
          if ($(this).hasClass('--tablet-swiper')) {
            if ($(window).width() >= general.mqBreakpoints.md) {
              self.initSwiperBehavior(swiperListing);
            } else if(swiperListing) {
              self.killSwiper(swiperListing)
            }
          } else {
              self.initSwiperBehavior(swiperListing);
          }
  
        });
      }
    },
  
  
    "filtersSwiper": function() {
      let self = this;
  
      if ($('.inline-filters-swiper-container').length) {
        $('.inline-filters-swiper-container').each(function () {
          let filtersContainerW = document.querySelector('.inline-filters').offsetWidth;
          let filtersElts = document.querySelectorAll('.inline-filters li');
          let filtersEltsW = 0;
          for (let i = 0; i < filtersElts.length; i++) {
              filtersEltsW += filtersElts[i].offsetWidth;
          }
  
          $(this).find('.swiper-wrapper').removeClass('no-translate');

             if (filtersEltsW >= filtersContainerW) {
                swiperFilters.init();
                self.initSwiperBehavior(swiperFilters);
            } else if (swiperFilters) {
                self.killSwiper(swiperFilters);
                swiperFilters.detachEvents();
            }
          
          var swiperFilters = new Swiper($(this)[0], {
              slidesPerView: 'auto',
              spaceBetween: 16,
              preventClicks: true,
              init: false,
              breakpoints: {
                768: {
                  spaceBetween: 28,
                }
              },
              on: {
                  init: function () {
                      this.setTranslate(0);
                  },
                  resize: function () {
                      if (filtersElW >= filtersContainerW + 4) {
                        self.initSwiperBehavior(this);
                      } else if (this) {
                          self.killSwiper(this);
                          this.detachEvents();
                      }
                  },
              }
          });
        });
      }
    },

    "stepperSwiper": function() {
      let self = this;
      if ($('.stepper-swiper-container').length) {
       $('.stepper-swiper-container').each(function () {
         let slidesNb = $(this).attr('data-slides');
         let slides = $(this).find('.swiper-slide');
         let activeStepIndex;

         for (let i = 0; i < slides.length; i++ ) {
           if ($(slides[i]).hasClass('active')) {
             activeStepIndex = i;
           }
         }
         
         $(this).find('.swiper-wrapper').removeClass('no-translate');
         
           var swiperStepper = new Swiper($(this)[0], {
               slidesPerView: slidesNb,
               spaceBetween: 0,
               preventClicks: true,
               init: false,
               breakpoints: {
                   768: {
                     slidesPerView: 4.125,
                   },
               },
               on: {
                   init: function () {
                       this.setTranslate(-15);
                       self.initSwiperBehavior(this);
                       this.slideTo(activeStepIndex);
                   },
                   resize: function () {
                     if ($(window).width() < general.mqBreakpoints.md) {
                       this.init();
                       self.initSwiperBehavior(this);
                       this.setTranslate(-15);
                       this.slideTo(activeStepIndex);
                     } else {
                       self.killSwiper(this);
                     }
                   },
               }
           });

         if ($(window).width() < general.mqBreakpoints.md) {
           swiperStepper.init();
           swiperStepper.setTranslate(-15);
           swiperStepper.slideTo(activeStepIndex);
         }

       });
     }
    },

  

}

export default PPPSwipers;