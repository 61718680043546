import general from './main';
import  $ from "jquery";

const Toolbar = {

    "closeToolbar": function() {
        $('.js-toolbar').removeClass('active', true);
        $('html').removeClass('no-scroll', true);
        $('.dark-backdrop').removeClass('d-block', true);
        setTimeout(function(){
            $('.js-toolbar').removeClass('show', true);
        }, 300);
    },
    
    "toggleToolbar": function() {
        let self = this;
        if ($('.js-toolbar').length) {
            if ($(window).width() < general.mqBreakpoints.lg) {
            $('.js-toolbar-toggle').on('click', function() {
                $('.js-toolbar').toggleClass('active');
                $(this).toggleClass('active');
                $('html').toggleClass('no-scroll');
                $('.dark-backdrop').toggleClass('d-block');
                setTimeout(function(){
                $('.js-toolbar').toggleClass('show');
                }, 300);
            })

            $('body').on('click', function(e) {
                if ($('.js-toolbar').hasClass('show') && general.detectClickOutsideActive(e, $('.js-toolbar'))) {
                self.closeToolbar();
                }
            })

            } else {
                self.closeToolbar();
            }
        }
    },
};

export default Toolbar;