import  $ from "jquery";
import mailForm from './mailForm';

const addModal = {

    "clear": function(parent) {
        parent.find('.form-added-group').each(function() {
            $(this).remove();
        })
        parent.find('.form-error').each(function() {
            $(this).remove();
        })
        parent.find('.form-control').each(function() {
             $(this).val('');
         })
    },

    "init": function() {
        const self =this;
        if ($('.js-add-modal').length){
            $('.js-add-modal').each(function() {
                let modal = $(this);
                let modalId = $(this).attr('id');
                let validateBtn = modal.find('.js-add-modal-add');
                let cancelBtn = modal.find('.close');
                let newItemContainer = $('body').find('button[data-target="#' + modalId +'"').parents('.card-items').find('.js-add-modal-items');
                let newItemTemplate = $('#'+ modalId +'ResultScript').html();
                
               mailForm.mailError(modal);

               cancelBtn.on('click', function(e) {
                   self.clear(modal);
               })

                validateBtn.on('click', function(e) {
                    e.preventDefault();
                    let formFields = modal.find('.js-add-form-content');

                    formFields.each(function() {
                        let newItem = $(newItemTemplate);
                        
                        $(this).find('.form-control').each(function() {
                            let input = $(this);
                            let value = $(this).val();

                           mailForm.checkField(input);
                            
                            let valueName = $(this).attr('name');
                            newItem.find('.js-add-modal-'+ valueName +'').text(value);
                            if (modal.find('.form-error').length === 0) {
                                newItem.appendTo(newItemContainer);
                            }
                        })
            
                    })
                           
                    if (modal.find('.form-error').length === 0) {
                        modal.modal('hide');
                        self.clear(modal);
                    } 
                })
            })
        }
    },

}

export default addModal;

window.PPPaddModal = addModal;